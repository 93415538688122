import * as PropTypes from "prop-types"

import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"
import { Link } from "gatsby";

import Layout from "../components/layout"
import FrontpageSlug from "../components/frontpageslug"
import Footer from "../components/footer";

import styleFrontpage from "../components/frontpage.module.css"
import Img from "gatsby-image"


export default ({ data }) => {
  return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Welcome to No Goblin! Your video game rest stop on the information superhighway!</title>
          <link rel="canonical" href="https://nogoblin.com" />
          <meta property="og:title" content="No Goblin" />
          <meta property="og:description" content="Welcome to No Goblin! Your video game rest stop on the information superhighway!" />
          <meta property="og:url" content="https://nogoblin.com/" />
          <meta property="og:image" content="https://nogoblin.com/ms-icon-310x310.png" />
          <meta property="og:site_name" content="No Goblin" />
          <meta property="og:type" content="object" />
        </Helmet>

        <div className={styleFrontpage.capsule + ' ' + styleFrontpage.header}>
        <div className={styleFrontpage.headerLeft}>
          <Img fixed={data.file.childImageSharp.fixed} />
        </div>
        <div className={styleFrontpage.headerRight}>

            <h1>Hi! We're No Goblin! <Link to="/about">Yes, we're hiring!</Link></h1><h2> We make fun games with heart that are unlike anything else you've played.</h2>
            <h2>You might know us from projects like... </h2>
        </div>
        </div>

        {data.frontpageProjects.edges.map(({ node }) => 
        (
          <div key={node.id}><FrontpageSlug node={node}/></div>
         )
        )  
       }
      <div className={styleFrontpage.capsule + ' ' + styleFrontpage.centered}>
        <h2>Looking for more? We've also assembled a small collection of eBooks that are available to download instantly...</h2>
        <iframe src="https://itch.io/embed/331733" height="167" frameBorder="0"></iframe>
        <iframe src="https://itch.io/embed/177052" height="167" frameBorder="0"></iframe>
      </div>

 
      <div className={styleFrontpage.capsule + ' ' + styleFrontpage.centered}>
       <h2>Want to know more about us? There's a <Link to="/about">handy link</Link> for that too!</h2>
       <Footer />
</div>

      </Layout>
  )
}



export const query = graphql`
  query {
    file(relativePath: { eq: "images/frontpage-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 256, height: 256) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    frontpageProjects : allProjectsJson {
      edges {
        node {
          id
          name
          urlslug
          selltext_short   

          frontpage
          frontpage_override
          frontpage_keyart 
          {
            childImageSharp 
            {
              fluid(maxWidth:1920, maxHeight:1024, toFormat:JPG)
              {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }    
            }
          } 

          frontpage_logo : logo_long
          {
            childImageSharp 
            {
              fluid(maxWidth:800)
              {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }    
            }
          }
        }
      }
    }
 }
`